<template>
  <div class="wraper pagesxruku">

    <div id="printDiv" v-if="showPrinter">ahahahahahha</div>

    <SXRukuTable ref="SXRukuTable"></SXRukuTable>
    <!--    选择申购单-->
    <el-dialog title="选择申购单" :append-to-body="true" :visible.sync="dialogVisible_sgList" width="800px"
      custom-class="width_800 cus_dialog">
      <SXPurchaseTable v-if="dialogVisible_sgList" ref="SXPurchaseTable"></SXPurchaseTable>

      <div slot="footer">
        <el-button type="primary" @click="dialogVisible_sgList = false">关闭</el-button>
      </div>
    </el-dialog>

    <!--申购单入库&&新增入库-->
    <el-dialog title="" :visible.sync="dialogVisible_cgd" custom-class="cus_dialog cus_dialogFullHeight" width="90%">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnBetween " style="height: calc( 100% - 0px );width: 40%;">
          <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width: 100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-width="120px" style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">
                  <el-input :value="userinfo.username + '-' + EditItem.dept_name" size="mini" disabled></el-input>
                </el-form-item>

                <el-form-item v-if="EditItem.sg_num" label="申购单号:">
                  <el-input v-model="EditItem.sg_num" size="mini" disabled></el-input>
                </el-form-item>
                <el-form-item v-if="EditItem.sg_num" label="申购日期:">{{ EditItem.sg_cdate || '-' }}
                </el-form-item>

                <el-form-item label="*入库仓库:" prop="zc_storeid">
                  <el-cascader ref="myCascader" v-model="EditItem.zc_storeid" :options="areaList" size="mini"
                    :props="optionProps" :show-all-levels="true" filterable clearable placeholder="请选择"
                    @change="handleCascaderChange"></el-cascader>
                </el-form-item>

                <el-form-item label="*仓库管理员:">
                  <el-select v-model="EditItem.store_admin" size="mini" placeholder="请先选择仓库">
                    <el-option v-for="(item, index) in storeAdmins" :label="item.name"
                      :value="item.union_id"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="金额合计(元):">
                  <el-input v-model="EditItem.howmuch_rk" size="mini" disabled placeholder="填写入库单价和数量后自动核算"></el-input>
                </el-form-item>

                <el-form-item label="入库备注:">
                  <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="EditItem.rk_remark" size="mini"
                    placeholder="入库备注"></el-input>
                </el-form-item>

                <el-form-item label="相关图片:">
                  <div class="flexStart">
                    <div class="updimage" v-for="(f, idx) in files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;" />
                      <div class="flexCenter" @click="delImage(idx)"
                        style="position: absolute;top: -8px;right: -8px;width:20px;height:20px;
                          color: #fff;background-color:#409eff;border-radius: 50%;border: 1px solid #fff;cursor: pointer;">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                    <el-upload v-if="files.length < 3" action="" :http-request="uploadOss" :on-success="uploadCover"
                      :on-remove="handleRemove" :show-file-list="false" name="image">
                      <div class="flexCenter uploadBox" style="width: 100px;height: 100px;border:1px dashed #909399;">
                        <i class="el-icon-plus" style="font-size: 26px;"></i>
                      </div>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>

        </div>
        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-row v-if="!EditItem.sg_num" class="flexStart" style="width:100%;margin-top: 10px;">
            <el-button size="mini" type="primary" @click="dialogVisible_zcList = true" style="margin-left: 10px;">
              选择耗材
            </el-button>
            <el-button size="mini" type="danger" @click="delSlct" style="margin-left: 10px;">移除耗材</el-button>
            <el-button size="mini" type="primary" @click="dialogVisible_addsxhc = true">新增耗材</el-button>
            <!-- <el-button size="mini" type="primary" @click="dialogVisible_sxType=true">新增耗材分类</el-button> -->
          </el-row>
          <el-row class="flex_1" style="margin-top: 10px;width: 100%;height: calc( 100% - 50px )">
            <el-table ref="mySGtable" :data="arr_json" height="100%" stripe border
              @selection-change="handleSelectionChange" :row-key="(row) => { return row.id; }"
              style="width:fit-content;width:100%;">
              <el-table-column v-if="!EditItem.sg_num" type="selection" width="55" />
              <el-table-column label="操作" width="60" fixed="left" align="center">
                <template slot-scope="scope">
                  <el-tag size="mini" style="cursor: pointer;" type="danger" @click="removeHC(scope.$index)">移除</el-tag>
                </template>
              </el-table-column>
              <el-table-column type="index" label="序号" width="55" align="center" />
              <!--                <el-table-column prop="title" label="耗材名称/品牌/型号/分类" width="200" :show-overflow-tooltip="true">
                  <template slot-scope="scope">
                    <div class="flexStart">
                      <div style="color: #409EFF;">{{ scope.row.title }}</div>&nbsp;
                      <div>{{ scope.row.pinpai }}</div>&nbsp;
                      <div>{{ scope.row.xinghao }}</div>&nbsp;

                    </div>
                    <div class="flexStart" style="color: #909399;">分类：{{ scope.row.typename1 }}</div>
                  </template>
                </el-table-column>-->
              <el-table-column prop="title" label="耗材名称" show-overflow-tooltip min-width="250" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">耗材名称</span>
                </template>
              </el-table-column>
              <el-table-column prop="pinpai" label="品牌" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">品牌</span>
                </template>
              </el-table-column>
              <el-table-column prop="xinghao" label="型号" width="100" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">型号</span>
                </template>
              </el-table-column>
              <!--                <el-table-column prop="typename1" label="分类"  width="150" align="center"/>-->
              <el-table-column prop="storename" label="存放地" width="150" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">存放地</span>
                </template>
              </el-table-column>
              <el-table-column v-if="EditItem.sg_num" prop="cgJine" width="150"
                :show-overflow-tooltip="true" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">申购单价(元)</span>
                </template>
                <template slot-scope="scope">
                  <span style="color: #F56C6C">
                    {{ formatter(null, null, scope.row.cgJine)}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column v-if="EditItem.sg_num" prop="cgNum" width="150"
                :show-overflow-tooltip="true" align="center">
                <template slot="header">
                  <span style="color: #F56C6C">申购数量</span>
                </template>
                <template slot-scope="scope">
                  <span style="color: #F56C6C">{{ scope.row.cgNum }}{{ scope.row.jldw }}</span>
                </template>
              </el-table-column>
              <el-table-column   v-if="EditItem.sg_num" prop="cgTotal" label="申购总价(元)"
                :show-overflow-tooltip="true" width="120" :formatter="formatter">
                <template slot-scope="scope">
                  <span>{{ scope.row.cgTotal.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column   prop="jine" width="150" :show-overflow-tooltip="true"
                align="center">
                <template slot="header">
                  <span style="color: #F56C6C">*入库单价(元)</span>
                </template>
                <template slot-scope="scope">
                  <el-input type="number" v-model="scope.row.rkJine" @input="handleRKData" class="cgInput"
                    @mousewheel.native.prevent :controls="false" size="mini" :min="0"></el-input>
                </template>
              </el-table-column>
              <el-table-column   prop="jine" width="170" :show-overflow-tooltip="true"
                align="center">
                <template slot="header">
                  <span style="color: #F56C6C">*入库数量</span>
                </template>
                <template slot-scope="scope">
                  <el-input type="number" v-model="scope.row.rkNum" @input="handleRKData" class="cgInput"
                    @mousewheel.native.prevent :controls="false" size="mini" :min="0"></el-input>
                  <span style="width: 14px;height: auto;margin-left: 4px;display: inline-block;">{{
                    scope.row.jldw
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column   prop="rkTotal" label="入库总价(元)" :show-overflow-tooltip="true"
                width="150" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.rkTotal.toFixed(2) }}</span>
                </template>
              </el-table-column>
              <el-table-column   v-if="false" prop="remark" label="入库备注"
                :show-overflow-tooltip="true" width="200">
                <template slot-scope="scope">
                  <el-input type="textarea" :rows="6" v-model="scope.row.rk_remark" size="mini" @input="handleRemark"
                    style="width: 100%;" placeholder="备注"></el-input>
                </template>
              </el-table-column>

              <el-table-column   v-if="EditItem.sg_num" prop="user_remark" label="备注"
                :show-overflow-tooltip="true" width="200">
                <template slot-scope="scope">
                  <div>{{ scope.row.user_remark || '-' }}</div>
                </template>
              </el-table-column>
              <el-table-column   v-if="EditItem.sg_num" prop="img" label="图片" width="200">


                <template slot-scope="scope">

                  <div class="flexStart">
                    <div v-if="scope.row.img" class="updimage"
                      style="position: relative;margin-top:10px;width: 100px;height: 100px;">
                      <img :src="scope.row.img" @click="handlePictureCardPreview(scope.row.img)"
                        style="width: 100px;height: 100px;cursor:pointer;">
                    </div>
                    <div v-else class="">-</div>

                  </div>
                </template>
              </el-table-column>
              <el-table-column   v-if="EditItem.sg_num" prop="externalLinks" label="链接" width="250">
                <template slot-scope="scope">
                  <div>
                    <div v-if="scope.row.links && scope.row.links.length > 0" v-for="(item, index) in scope.row.links">
                      <el-link :href="item" target="_blank" type="primary">{{ item }}</el-link>
                    </div>
                    <div v-if="!scope.row.links || scope.row.links.length == 0">-</div>
                  </div>
                </template>
              </el-table-column>
            </el-table>


          </el-row>

        </div>
      </div>

      <div slot="footer">
        <el-button type="primary" size="small" @click="saveForm()">提交</el-button>

        <el-button type="primary" @click="dialogVisible_cgd = false" plain size="small">取消</el-button>
      </div>

    </el-dialog>
    <!--    添加耗材-->
    <SXhcAdd v-if="dialogVisible_addsxhc" :dialogVisible_addsxhc="dialogVisible_addsxhc"></SXhcAdd>

    <!--添加分类-->
    <el-dialog title="实训耗材分类" :visible.sync="dialogVisible_sxType" width="600px" custom-class="width_800 cus_dialog">

      <sxType name="实训耗材分类" type="sx" parentPage="sxlist"></sxType>

    </el-dialog>


    <!--        选择耗材-->
    <SXListTable v-if="dialogVisible_zcList" :dialogVisible_zcList="dialogVisible_zcList" dialogTitle="选择耗材"
      ref="SXListTable"></SXListTable>


    <!--查看-->
    <RKDetail v-if="dialogVisible_detail" :dialogVisible_detail="dialogVisible_detail" :dialogTitle="dialogTitle"
      :EditItem="EditItem"></RKDetail>

    <!--审核-->
    <RKVerify v-if="dialogVisible_verify" :dialogVisible_verify="dialogVisible_verify" :dialogTitle="dialogTitle"
      :EditItem="EditItem"></RKVerify>

    <Deptuser v-if="showUserDialog" @getUser="getUser" @closeUser="closeUser"></Deptuser>

    <div style="display: none;">
      <el-upload action="" :multiple="true" :http-request="uploadOss" :on-success="uploadAttch" :show-file-list="false"
        name="image">

        <el-button size="mini" id="addXlsbtn">+</el-button>
      </el-upload>
    </div>


    <el-dialog title="错误日志" :visible.sync="dialogErrorVisible" :close-on-click-modal="false">
      <div style="padding-top: 10px;" v-for="item in ErrLog">{{ item }}</div>
    </el-dialog>

    <!--预览图片-->
    <el-dialog width="600px" :visible.sync="dialogVisibleImageUrl" @close="dialogVisibleImageUrl = false"
      :modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

    <!--打印单-->
    <el-dialog :title="dialogTitle" :visible="dialogVisible_print" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog cus_dialog_print" @close="dialogVisible_print = false">
      <div style="text-align: right; position: absolute; top:2px; right: 50px;">
        <el-button v-print="'#printMe'" icon="el-icon-printer" type="warning">打印</el-button>
      </div>
      <RKPrintForm v-if="dialogVisible_print" :printId="printId"></RKPrintForm>

    </el-dialog>

    <!--    匹配-->
    <el-dialog :title="dialogTitle" :visible="dialogVisible_match" :close-on-click-modal="false" width="900px"
      custom-class="cus_dialog" @close="dialogVisible_match = false">

      <el-row class="flex_1" style="margin: 30px 10px;width: calc( 100% - 20px );max-height: 45vh;">
        <el-table ref="mySGtable" :data="matchList" height="100%" border style="width: 100%;height: 100%;">

          <el-table-column type="index" label="序号" width="50" />
          <el-table-column prop="title" label="耗材名称" width="200" :show-overflow-tooltip="true" />
          <!-- <el-table-column prop="typename1" label="耗材分类" width="120" :show-overflow-tooltip="true" /> -->
          <el-table-column prop="pinpai" label="耗材品牌" />
          <el-table-column prop="xinghao" label="耗材型号" :show-overflow-tooltip="true" />
          <el-table-column prop="cgJine" label="预估价格" width="80" :show-overflow-tooltip="true" align="center" />

          <el-table-column prop="rkJine" label="实际价格" width="80" :show-overflow-tooltip="true" align="center" />
          <el-table-column prop="user_remark" label="匹配结果" :show-overflow-tooltip="true" width="80">
            <template slot-scope="scope">
              <span v-if="(scope.row.cgJine == scope.row.rkJine) && (scope.row.cgNum == scope.row.rkNum)">正常</span>
              <span v-if="(scope.row.cgJine != scope.row.rkJine) || (scope.row.cgNum != scope.row.rkNum)"
                style="color: #F56C6C">异常</span>
            </template>
          </el-table-column>

          <el-table-column prop="user_remark" label="异常详情" :show-overflow-tooltip="true" width="150">
            <template slot-scope="scope">
              <div v-if="scope.row.cgNum != scope.row.rkNum" style="color: #F56C6C">数量不匹配</div>
              <div v-if="scope.row.cgJine != scope.row.rkJine" style="color: #F56C6C">金额不匹配</div>
              <div v-if="scope.row.cgNum == scope.row.rkNum && scope.row.cgJine == scope.row.rkJine">-</div>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Deptuser from '../../com/deptuser.vue'

export default {
  components: {
    Deptuser,
    SXRukuTable: () => {
      return import('./com_sxruku/SXRukuTable.vue')
    },
    SXPurchaseTable: () => {
      return import('./com_sxruku/SXPurchaseTable.vue')
    },
    SXListTable: () => {
      return import('../components/SXListTable.vue')
    },
    sxType: () => {
      return import('../components/Type.vue')
    },
    SXhcAdd: () => {
      return import('../components/SXhcAdd')
    },
    RKDetail: () => {
      return import('./com_sxruku/RKDetail.vue')
    },
    RKVerify: () => {
      return import('./com_sxruku/RKVerify.vue')
    },
    RKPrintForm: () => {
      return import('../../../h5/print/rk.vue')
    },
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value && value != 0) {
        return callback(new Error('请填写入库数量!'));
      }
      if (!Number.isInteger(value)) {
        callback(new Error('请输入数字值'));
      } else {
        if (value <= 0) {
          callback(new Error('不能小于0'));
        } else {
          callback();
        }
      }
    };

    return {
      curDate: '',
      userinfo: {},
      searchForm: {},
      dialogTitle: '新增',
      dialogVisible_sgList: false,
      dialogVisible_cgd: false,

      EditItem: {
        arr_json: ''
      },
      arr_json: [],
      dialogVisibleImageUrl: false,
      dialogImageUrl: '',//预览图片
      dialogVisible_addsxhc: false,
      dialogVisible_detail: false,
      dialogVisible_verify: false,
      dialogVisible_sxType: false,
      DataList: [],
      multipleSelection: [],
      queryForm: {},
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },

      typeList: [],
      areaList: [],
      optionProps: { value: 'id', label: 'storename', emitPath: false, },
      files: [],
      logList: [],
      dialogVisible_borrow: false,

      showUserDialog: false,
      userDialogType: null,
      dialogVisible_return: false,
      dialogVisible_distribute: false,
      dialogVisible_shenling: false,
      dialogVisible_withdraw: false,
      drawer: false,//高级搜索面板
      FieldItem: {},
      showCusField: false,
      printObj: {
        id: "#printDiv",
        popTitle: "",
        extraCss: "",
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      showPrinter: false,
      bqZcList: [],
      dialogErrorVisible: false,
      ErrLog: [],
      page_hc: {
        current_page: 1,
        pre_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      dialogVisible_zcList: false,
      storeNum: null,
      rules_store: {
        num: [
          { validator: checkNum, trigger: 'blur' }
        ],
      },
      storeAdmins: [],
      dialogVisible_print: false,
      printId: '',
      dialogVisible_match: false,
      matchList: []
    }
  },
  mounted() {
    this.getList();
    this.getType();
    this.getArea();
    this.getCurDate();
    this.getCreateUser()
  },
  methods: {
    formatter(row, col, cellValue, index) {
      return cellValue ? Number(cellValue).toFixed(2) : cellValue
    },
    getList() {
      let _this = this
      let params = {}, arr = []
      params.title = _this.searchForm.title
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = 'sx'
      this.$http.post("/api/sx_sg_list", params).then(res => {
        this.DataList = res.data.data;
        if (res.data && res.data.page) {
          this.page = res.data.page
          this.page_hc = res.data.page
        }

      })
    },
    getCreateUser() {
      this.$http.post("/api/get_teacher_info").then(res => {
        this.userinfo = res.data
      })
    },
    // 选择申购单
    initForm() {
      this.EditItem = { arr_json: '' }
      if (this.userinfo.dept && this.userinfo.dept.length > 0) {
        this.EditItem.dept_name = this.userinfo.dept_name
        this.EditItem.dept_id = this.userinfo.dept_id
      } else {
        this.EditItem.dept_name = ''
        this.EditItem.dept_id = ''
      }
      this.arr_json = []
      this.files = []
      this.storeAdmins = []
    },
    chooseSG(row) {
      let _this = this
      this.initForm()
      let obj = JSON.parse(JSON.stringify(row))
      if (obj.hc_list) {
        let arr = JSON.parse(obj.hc_list)
        arr.map(a => {
          a.rkJine = a.cgJine
          a.rkNum = a.cgNum
          a.rkTotal = a.cgTotal

          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            } else {
              a.links = [a.externalLinks]
            }
          }
        })
        obj.hc_list = arr
        console.log(610, arr)
      } else {
        obj.hc_list = []
      }

      obj.howmuch_rk = obj.howmuch
      obj.sg_cdate = obj.cdate
      this.EditItem = obj

      this.setStoreDefaultValue();

      this.arr_json = obj.hc_list
      _this.$nextTick(() => {

        console.log(_this.arr_json)
        this.dialogVisible_sgList = false
        this.dialogTitle = '申购单入库'
        this.dialogVisible_cgd = true
        _this.$forceUpdate()
      })
    },
    //设置仓库默认值
    setStoreDefaultValue() {
      this.EditItem.zc_storeid = [this.areaList[0].id];
      let arr = JSON.parse(this.areaList[0].remark)
      this.storeAdmins = arr
      this.EditItem.store_admin = arr && arr.length ? arr[0].union_id : ""
    },
    //新增入库
    add() {
      let _this = this
      this.initForm()
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        this.dialogTitle = '新增入库'
        this.dialogVisible_cgd = true
        _this.$forceUpdate()
      })
    },

    uploadCover(e) {
      this.files.push({ fileName: e.fileName, src: e.src, name: e.fileName, url: e.src })
    },
    delImage(idx) {
      this.files.splice(idx, 1)
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisibleImageUrl = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.files = fileList
    },
    uploadPreview(file) {
      // console.log(879,file)
      window.open(file.src || file.url)
    },
    callBack_addsxhc(row) {
      this.$http.post("/api/sx_hc_xx_list", { id: row.id }).then(res => {
        console.log(3747673738497879, res.data.data[0])
        let row1 = res.data.data ? res.data.data[0] : row
        this.chooseHC(row1)
      })
    },
    chooseHC(row) {
      let _this = this
      let obj = {}, arr = []
      obj = JSON.parse(JSON.stringify(row))
      arr = JSON.parse(JSON.stringify(_this.arr_json))
      obj.img = ''
      obj.rkJine = obj.rkJine ? obj.rkJine : obj.jine
      obj.rkNum = obj.rkNum ? obj.rkNum : 1
      if (arr && arr.length > 0) {
        if (_this.dep_Arr1(arr, obj.id)) {//dep_Arr1 id存在为false 不存在返回true
          arr.push(obj)
          _this.arr_json = arr
        } else {
          _this.$message.warning(`${obj.title}已存在,不要重复选择哦~`)
        }
      } else {
        arr.push(obj)
        _this.arr_json = arr
      }
      let total = 0
      if (_this.arr_json && _this.arr_json.length > 0) {
        _this.arr_json.map(a => {
          a.rkTotal = 0
          if ((!a.rkJine && a.rkJine != 0) || (!a.rkNum && a.rkNum != 0)) {
            a.rkTotal = 0
          } else {
            a.rkTotal = this.$mul(a.rkJine, a.rkNum)
          }
          total += a.rkTotal
        })
        this.EditItem.howmuch_rk = total
      } else {
        this.EditItem.howmuch_rk = 0
      }
      _this.$nextTick(() => {
        console.log(_this.arr_json)
        _this.$forceUpdate()
      })

    },

    dep_Arr1(arr, id) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.id == id) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },
    removeHC(idx) {
      let _this = this
      _this.arr_json.splice(idx, 1)
      let total = 0
      if (_this.arr_json && _this.arr_json.length > 0) {
        _this.arr_json.map(a => {
          a.rkTotal = 0
          if ((!a.rkJine && a.rkJine != 0) || (!a.rkNum && a.rkNum != 0)) {
            a.rkTotal = 0
          } else {
            a.rkTotal = this.$mul(a.rkJine, a.rkNum)
          }
          total += a.rkTotal
        })
        this.EditItem.howmuch_rk = total
      } else {
        this.EditItem.howmuch_rk = 0
      }
      _this.$nextTick(() => {
        // console.log(_this.arr_json)
        _this.$forceUpdate()
      })

    },
    handleRKData(index) {
      console.log('handleRKData')
      let _this = this
      let total = 0
      if (this.arr_json) {
        this.arr_json.map(a => {
          a.rkTotal = 0
          if ((!a.rkJine && a.rkJine != 0) || (!a.rkNum && a.rkNum != 0)) {
            a.rkTotal = 0
          } else {
            a.rkTotal = this.$mul(a.rkJine, a.rkNum)
            console.log(604, a.rkTotal, a.rkJine, a.rkNum)
          }
          total += a.rkTotal
        })
        this.EditItem.howmuch_rk = total
        this.EditItem = { ..._this.EditItem }

      } else {
        this.EditItem.howmuch_rk = 0
        this.EditItem = { ..._this.EditItem }
      }
    },
    handleRemark() {
      console.log(this.arr_json)
    },

    uploadCover1(e, b, c, index) {
      // console.log(1082,e,b,c,index)
      let _this = this
      this.arr_json[index].img = e.src
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
      // this.files.push({fileName: e.fileName, src: e.src, name: e.fileName, url: e.src})
    },
    delImage1(index) {
      let _this = this
      this.arr_json[index].img = ''
      this.arr_json = [..._this.arr_json]
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },

    view(row, type) {
      console.log(row, type)
      this.initForm()
      let obj = JSON.parse(JSON.stringify(row))
      obj.arr_json = obj.arr_json ? JSON.parse(obj.arr_json) : []
      obj.files = obj.files ? JSON.parse(obj.files) : []
      // obj.hc_list = obj.hc_list ? JSON.parse(obj.hc_list) : []
      if (obj.hc_list && obj.hc_list.length > 0) {
        let arr = this.isJson(obj.hc_list) ? JSON.parse(obj.hc_list) : obj.hc_list
        arr.map(a => {
          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            } else {
              a.links = [a.externalLinks]
            }
          }

        })
        obj.hc_list = arr
      }
      this.EditItem = obj
      if (type == 'verify') {
        this.dialogTitle = '审核'
        this.dialogVisible_verify = true
      } else {
        this.dialogTitle = '入库单详情'
        this.dialogVisible_detail = true
      }
    },
    isJson(str) {
      if (isNaN(Number(str))) {
        try {
          JSON.parse(str);
          return true;
        } catch (e) {
          return false;
        }

      } else {
      }
      return false;
    },
    //一键入库
    rkbatch() {
      console.log('一件入库')
    },
    print(row) {
      this.printId = row.id
      this.dialogVisible_print = true
      this.dialogTitle = '打印入库单'
    },
    match(row) {
      if (row.hc_list) {
        let matchList = JSON.parse(row.hc_list)
        this.matchList = matchList
      }
      this.dialogVisible_match = true
      this.dialogTitle = `${row.rk_num}入库匹配结果`
      console.log(this.matchList)
    },
    editItem(row) {
      let _this = this
      this.EditItem = { arr_json: '' }
      let obj = JSON.parse(JSON.stringify(row))
      obj.files = obj.files ? JSON.parse(obj.files) : []
      if (obj.hc_list && obj.hc_list.length > 0) {
        let arr = this.isJson(obj.hc_list) ? JSON.parse(obj.hc_list) : obj.hc_list

        arr.map(a => {
          let links = []
          a.links = []
          if (a.externalLinks) {
            if (a.externalLinks.indexOf(';') > 0) {
              links = a.externalLinks.split(';')
              a.links = links
            } else if (a.externalLinks.indexOf('；') > 0) {
              links = a.externalLinks.split('；')
              a.links = links
            } else {
              a.links = [a.externalLinks]
            }
          }

        })
        obj.hc_list = arr
      }
      obj.store_admin = parseInt(obj.store_admin)
      this.getStoreAdmins(obj.zc_storeid).then(r => {
        this.EditItem = obj
      })
      this.arr_json = (obj.hc_list && obj.hc_list.length > 0) ? obj.hc_list : []
      this.files = (obj.files && obj.files.length > 0) ? obj.files : []
      this.dialogTitle = '编辑'
      this.dialogVisible_cgd = true
      _this.$nextTick(() => {
        _this.$forceUpdate()
      })
    },
    getStoreAdmins(id) {
      return new Promise(resolve => {
        this.$http.post("/api/zcstore_list_by_id", { id: id }).then(res => {
          if (res.data.remark) {
            this.storeAdmins = JSON.parse(res.data.remark)
          }
          resolve()
        })
      })

    },
    onSearch() {
      this.page.current_page = 1
      this.getList()
    },
    onReset() {
      this.queryForm = {}
      this.page.current_page = 1
      this.getList()
    },
    daochu(command) {
      return
      document.getElementById("addXlsbtn").click()

    },
    dep_Arr(arr) {
      let a = true
      let fun = function (b) {
        b.map(s => {
          if (s.store_num <= 0) {
            a = false
            return;
          }
        })
      }
      fun(arr)
      return a
    },

    refreshType() {
      console.log(463, 'refreshType')
      this.getType()
      this.dialogVisible_sxType = false
    },
    getType() {
      let _this = this
      this.$http.post("/api/zc_type", { type: 'hc' }).then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.typeList = result
        })
      })
    },
    getArea() {
      let _this = this
      this.$http.post("/api/zc_store").then(res => {
        _this.deleteEmptyGroup(res.data).then(result => {
          _this.areaList = result;
          // setTimeout(()=>{
          //   console.log('init 默认值')
          //   _this.EditItem.zc_storeid = [31];
          // },3000)

        })
      })
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise(resolve => {
        function traversal(data) {
          data.map(item => {
            for (let info in item) {
              if (item['children']) {
                if (item['children'].length > 0) {
                  traversal(item['children'])
                }
                if (item['children'].length == 0) {
                  delete item['children']
                }
              }
            }
          })
        }

        traversal(treeData)
        resolve(treeData)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },

    saveForm() {
      let flag = 1
      let _this = this
      let params = {}
      console.log(this.EditItem)
      params = { ..._this.EditItem }
      if (!params.zc_storeid) {
        _this.$message.warning('请选择入库仓库~')
        return
      }
      if (!params.store_admin) {
        _this.$message.warning('请选择仓管~')
        return
      }
      if (!_this.arr_json || _this.arr_json == 0) {
        _this.$message.warning('请至少添加一条耗材~')
        return
      } else {
        _this.arr_json.map(a => {
          console.log(755755, a.rkNum)
          if (!a.rkJine) {
            _this.$message.warning(`请填写${a.title}的入库金额~`)
            flag = 0
            return
          }
          if (!a.rkNum) {
            _this.$message.warning(`请填写${a.title}的入库数量~`)
            flag = 0
            return
          }
        })
        params.hc_list = _this.arr_json
        params.title = `${_this.userinfo.username}发起了${_this.arr_json[0].title}${_this.arr_json.length > 1 ? ('等' + _this.arr_json.length + '件物品') : ''}的入库单`
      }
      params.files = JSON.stringify(_this.files)

      if (_this.dialogTitle == '申购单入库' || _this.dialogTitle == '新增入库') {
        delete params.id
      }

      if (flag) {
        this.$http.post('/api/sx_hc_rk_save', params).then(res => {
          _this.dialogVisible_cgd = false;
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          _this.$refs.SXRukuTable.getList()
        })
      }

    },


    removeItem(item) {
      let _this = this
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/sx_hc_rk_delete", {
          id: item.id,
          sg_num: item.sg_num,
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });
          _this.$refs.SXRukuTable.getList()
        })

      }).catch(() => {

      });
    },
    delSlct() {
      let _this = this
      if (_this.multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条数据')
      } else {
        _this.$confirm('此操作将移除所选数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(974, _this.multipleSelection, _this.arr_json)

          _this.multipleSelection.map(a => {
            let len = _this.arr_json.length
            for (let i = len - 1; i >= 0; i--) {
              if (a.id == _this.arr_json[i].id) {
                _this.arr_json.splice(i, 1)
              }
            }

          })
          console.log(985, this.arr_json)



          let total = 0
          if (_this.arr_json && _this.arr_json.length > 0) {
            _this.arr_json.map(a => {
              a.rkTotal = 0
              if ((!a.rkJine && a.rkJine != 0) || (!a.rkNum && a.rkNum != 0)) {
                a.rkTotal = 0
              } else {
                a.rkTotal = this.$mul(a.rkJine, a.rkNum)
              }
              total += a.rkTotal
            })
            this.EditItem.howmuch_rk = total
          } else {
            this.EditItem.howmuch_rk = 0
          }
          _this.$nextTick(() => {
            // console.log(_this.arr_json)
            _this.$forceUpdate()
          })


        }).catch(() => {

        });
      }
    },

    cancelRuku(rows) {
      let _this = this
      let flag = false
      let multipleSelection = JSON.parse(JSON.stringify(rows))
      if (multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条数据')
      } else {
        multipleSelection.map(m => {
          if (m.sp_status != 1) {
            console.log(m)
            flag = true
            setTimeout(() => {
              _this.$message.warning({
                showClose: true,
                message: `入库单${m.rk_num}尚未入库或已取消入库，请取消勾选该条数据~`
              })
            }, 50)
            return
          }
        })
        if (!flag) {
          _this.$confirm('取消入库操作不可逆, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            _this.$http.post("/api/sx_hc_rk_revoke", {
              ids: multipleSelection.map(item => {
                return item.id
              }).join(',')
            }).then(res => {
              _this.$message.success(res.data.msg);
              _this.$refs.SXRukuTable.getList()
            })
          }).catch(() => {

          });
        }

      }
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
    handleSizeChange_hc(val) {
      this.page_hc.current_page = 1
      this.page_hc.pre_page = val
      this.getList();
    },
    paginate_hc(val) {
      this.page_hc.current_page = val
      this.getList();
    },
    handleCascaderChange(e) {
      if (e) {
        let _this = this
        _this.EditItem.zc_storeid = e
        let a = _this.$refs['myCascader'].getCheckedNodes()[0].data
        console.log(1114, a.remark)
        if (a.remark) {
          let arr = JSON.parse(a.remark)
          this.storeAdmins = arr
          this.EditItem.store_admin = arr && arr.length ? arr[0].union_id : ""
        }
      }
    },
    handleCascaderChange1(e) {
      if (e) {
        let _this = this
        _this.EditItem.zc_typeid = e
        let a = _this.$refs['myCascader1'].getCheckedNodes()[0].data
        // console.log(1114, a)
        _this.FieldItem = a
        _this.EditItem.ename = a.ename
        _this.EditItem.jldw = a.unit
        if (a.zdjson && a.zdjson != '') {
          _this.EditItem.zdjson = JSON.parse(a.zdjson)
        }
        _this.EditItem = { ..._this.EditItem }
      }
    },
    handleCascaderChange2(e) {
      // console.log(e)
      if (e) {
        let _this = this
        _this.EditItem.zc_whereid = e
        let a = _this.$refs['myCascader2'].getCheckedNodes()[0].data
        let allCates = _this.getFathersById(e, _this.areaList, 'id', 'children', 'storename')
        allCates.pop();
        let ss = allCates.length > 0 ? `${allCates.join('/')}/${a.storename}` : a.storename
        _this.EditItem.zc_where = ss
        console.log(_this.EditItem.zc_where)
        _this.EditItem = { ..._this.EditItem }
      }
    },
    /**
     * 查找匹配的完整路径(arrRes:id集合;arrText:中文集合;)
     * id:     匹配的值
     * data: 匹配的数组数据
     * prop: 匹配的字段名
     * children: 子集label
     * cname: 输出数组需要的字段名
     */
    getFathersById(id, data, prop, children, cname) {
      var arrRes = [], arrText = []
      const rev = (data, nodeId) => {
        for (var i = 0, length = data.length; i < length; i++) {
          const node = data[i]
          if (node[prop] == nodeId) {
            arrRes.unshift(node[prop])
            arrText.unshift(node[cname])
            return true
          } else {
            if (node[children] && node[children].length) {
              if (rev(node[children], nodeId)) {
                arrRes.unshift(node[prop])
                arrText.unshift(node[cname])
                return true
              }
            }
          }
        }
        return false
      }
      rev(data, id)
      return arrText  //返回中文集合
    },

    showUser(type) {
      if (type) {
        this.userDialogType = type
      }
      this.showUserDialog = true
    },
    del_target_users(index) {
      let _this = this
      this.EditItem.target_users.splice(index, 1)
      this.EditItem = { ..._this.EditItem }
    },
    getUser(e) {
      console.log(e)
      let _this = this
      let arr = []
      if (e && e.length > 0) {
        if (this.userDialogType == 1) {
          e.map((item, idx) => {
            arr.push({
              union_id: item.union_id,
              name: item.name,
            })
            if (_this.EditItem.target_users && _this.EditItem.target_users.length > 0) {
              _this.EditItem.target_users.map(orgnl => {
                if (orgnl.union_id == item.union_id) {
                  arr.splice(idx, 1)
                }
              })
            }
          })
          _this.EditItem.target_users = _this.EditItem.target_users.concat(arr)
          _this.EditItem = { ..._this.EditItem }
        } else if (this.userDialogType == 2) {
          _this.EditItem.target_uid = e[0].union_id
          _this.EditItem.name = e[0].name
          _this.EditItem = { ..._this.EditItem }
        }

      }
      _this.showUserDialog = false
    },
    closeUser() {
      this.showUserDialog = false
    },
    addCusField() {
      let _this = this
      if (!this.FieldItem.id) {
        this.$message.warning('请先选择资产分类！')
        return;
      }
      this.showCusField = true
      console.log(1103, _this.FieldItem.zdjson)
      if (_this.FieldItem.zdjson && _this.FieldItem.zdjson != '' && _this.FieldItem.zdjson != 'null' && _this.FieldItem.zdjson != 'undefined') {
        _this.FieldItem.zdjson = JSON.parse(_this.FieldItem.zdjson)
      } else {
        _this.FieldItem.zdjson = []
      }
      _this.FieldItem = { ..._this.FieldItem }
    },
    saveCusField() {
      let _this = this
      let objvalue = _this.EditItem.zdjson    //保存后回显防止旧值被覆盖
      let params = {}, obj = {}
      obj = JSON.parse(JSON.stringify(_this.FieldItem))
      params = { ...obj }
      params.remark = ""
      params.zdjson = JSON.stringify(obj.zdjson)
      this.$http.post("/api/zc_type_save", params).then(res => {
        _this.showCusField = false
        _this.$message({
          type: 'success',
          message: '保存成功'
        });
        _this.getType()
        _this.EditItem.zdjson = JSON.parse(params.zdjson)
        for (let a in objvalue) {
          for (let b in _this.EditItem.zdjson) {
            if (a == b && objvalue[a] != '') {
              _this.EditItem.zdjson[b] = objvalue[a]
            }
          }
        }
        _this.EditItem = { ..._this.EditItem }
      })
    },
    addCus() {
      let _this = this
      this.FieldItem.zdjson.push({})
      this.FieldItem = { ..._this.FieldItem }
    },
    delCus(item, index) {
      let _this = this
      this.FieldItem.zdjson.splice(index, 1)
      this.FieldItem = { ..._this.FieldItem }
    },

    print_ruku() {
      this.$alert("敬请期待")
      return;
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请选择一条数据');
        return
      } else {
        this.showPrinter = true
      }
    },
    getCurDate() {
      let d = new Date();
      let year = d.getFullYear(); //获取完整的年份(4位)
      let month = (d.getMonth() + 1).toString().padStart(2, '0'); //获取当前月份(0-11,0代表1月)
      let date = (d.getDate()).toString().padStart(2, '0'); //获取当前日(1-31)
      d.getDay(); //获取当前星期X(0-6,0代表星期天)
      d.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
      d.getHours(); //获取当前小时数(0-23)
      d.getMinutes(); //获取当前分钟数(0-59)
      d.getSeconds(); //获取当前秒数(0-59)
      d.getMilliseconds(); //获取当前毫秒数(0-999)
      d.toLocaleDateString(); //获取当前日期
      let mytime = d.toLocaleTimeString(); //获取当前时间
      d.toLocaleString(); //获取日期与时间
      this.curDate = `${year}-${month}-${date}`
    },
    uploadAttch(e) {
      console.log(e)
      this.$http.post("/api/import_zc_xls", { url: e.src }).then(res => {
        if (res.data.errordata.length > 0) {
          this.ErrLog = res.data.errordata
          this.dialogErrorVisible = true;

        }
        this.getList();
      })
    }
  }
}
</script>

<style lang="less" type="text/less">
.pagesxruku {
  box-shadow: 0 0 5px #409eff81;
  padding-left: 10px;
  padding-bottom: 10px;

  div {
    box-sizing: border-box !important;
  }

  position: relative;

  .addDialog {
    background-color: #fff;
    //border: 1px solid #409eff;
    box-shadow: 0 0 5px #409eff81;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: calc(100%);
    height: calc(100%);

    .el-dialog__wrapper {
      position: absolute !important;
    }

    .v-modal {
      position: absolute !important;
    }
  }

  .myDialogBox {
    width: 100%;
    height: 100%;

    .shadow {
      box-shadow: 0 0 5px #409eff81;
    }

    .titleBar {
      width: 100%;
      height: 30px;
      background-color: #2469f351;
      font-size: 14px;
      flex-wrap: wrap;
    }

    .titleBar .div {
      width: 100%;
      height: calc(100% - 2px);
      padding-left: 22px;
      border-left: none;
      color: #303133;
      font-weight: 700
    }

    .el-form-item {
      margin-bottom: 0;

      .el-textarea__inner {
        margin: 10px 0;
      }
    }

    .cgInput {
      max-width: 120px;

      .el-input__inner {
        color: #F56C6C;
      }
    }

    .cgInput input::-webkit-outer-spin-button,
    .cgInput input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .cgInput input[type="number"] {
      -moz-appearance: textfield;
    }


  }

  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    font-size: 12px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .labelCss {
    .el-form-item__label {
      color: #909399 !important;
      font-size: 12px;
    }
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 100% !important;
  }

  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown+.el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .cus_dropdown {
    .el-button--primary {
      color: #409EFF !important;
      background-color: #Ecf5ff !important;
      border-color: #b3d8ff !important;
    }
  }

  .el-divider {
    margin-top: 0;
  }

  .el-drawer__wrapper {
    top: 70px;
  }

  .drawer_box,
  .targetUsers {
    padding: 10px;

    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .updimage {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: top;
    margin: 0 20px 0 0;
    border: 1px dotted #ccc;
    background-size: 100% auto;
    background-position: center;
    //overflow: hidden;
    background-repeat: no-repeat;
    //cursor: pointer;
  }

  .uploadBox:hover {
    border-color: #409eff !important;
    color: #409eff;
  }

  .cus_page {
    margin-top: 10px;

    .el-pagination__sizes {
      width: 130px;
    }
  }
}
</style>
